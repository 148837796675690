exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-templates-page-details-jsx-content-file-path-content-pages-privacy-policy-index-md": () => import("./../../../src/templates/page-details.jsx?__contentFilePath=/opt/build/repo/content/pages/privacy-policy/index.md" /* webpackChunkName: "component---src-templates-page-details-jsx-content-file-path-content-pages-privacy-policy-index-md" */),
  "component---src-templates-post-details-jsx-content-file-path-content-posts-1-hello-world-index-md": () => import("./../../../src/templates/post-details.jsx?__contentFilePath=/opt/build/repo/content/posts/1-hello-world/index.md" /* webpackChunkName: "component---src-templates-post-details-jsx-content-file-path-content-posts-1-hello-world-index-md" */),
  "component---src-templates-post-details-jsx-content-file-path-content-posts-2-creating-new-python-project-index-md": () => import("./../../../src/templates/post-details.jsx?__contentFilePath=/opt/build/repo/content/posts/2-creating-new-python-project/index.md" /* webpackChunkName: "component---src-templates-post-details-jsx-content-file-path-content-posts-2-creating-new-python-project-index-md" */),
  "component---src-templates-post-details-jsx-content-file-path-content-posts-3-link-shortener-index-md": () => import("./../../../src/templates/post-details.jsx?__contentFilePath=/opt/build/repo/content/posts/3-link-shortener/index.md" /* webpackChunkName: "component---src-templates-post-details-jsx-content-file-path-content-posts-3-link-shortener-index-md" */),
  "component---src-templates-post-details-jsx-content-file-path-content-posts-4-ckad-index-md": () => import("./../../../src/templates/post-details.jsx?__contentFilePath=/opt/build/repo/content/posts/4-ckad/index.md" /* webpackChunkName: "component---src-templates-post-details-jsx-content-file-path-content-posts-4-ckad-index-md" */),
  "component---src-templates-post-list-for-tag-jsx": () => import("./../../../src/templates/post-list-for-tag.jsx" /* webpackChunkName: "component---src-templates-post-list-for-tag-jsx" */),
  "component---src-templates-post-list-jsx": () => import("./../../../src/templates/post-list.jsx" /* webpackChunkName: "component---src-templates-post-list-jsx" */)
}

